export default defineI18nConfig(() => ({
    legacy: false,
    messages: {
        'de-DE': {
            // Common
            back_home: 'Zurück zur Startseite',
            error: 'Fehler',

            // Error-Messages
            error_message_401: 'Das ist leider nicht für Ihre Augen bestimmt!',
            error_message_403: 'Das ist leider nicht für Ihre Augen bestimmt!',
            error_message_404: 'Was auch immer Sie suchen, es scheint nicht zu existieren!',
            error_message_500: 'Da ist etwas scheifgelaufen!'
        }
    }
}));